.demo-wrapper{
    height: 400px;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
}
.toolbar-wrapper{
    display: none;
}
.pointer{
    cursor: pointer;
}